import { IAccessControlContext } from '@refinedev/core'
import { USER_KEY } from './constants'

export const accessControlProvider: Required<IAccessControlContext> = {
  options: {
    buttons: {
      enableAccessControl: true,
      hideIfUnauthorized: true,
    },
  },
  can: async ({ resource, action, params }) => {
    const userData = localStorage.getItem(USER_KEY)
    const roles = userData ? JSON.parse(userData)?.roles : []

    // дэшборд всем , кроме merchant_support
    if (
      (resource === 'dashboard' || resource === 'dashboards') &&
      !roles.includes('merchant_support')
    ) {
      return Promise.resolve({ can: true })
    }

    // все могут разлогиниться
    if (resource === 'access_tokens' && action === 'delete') {
      return Promise.resolve({ can: true })
    }

    // у всех профиль
    if (resource === 'profile') {
      return Promise.resolve({ can: true })
    }

    // GOD mode
    // unless dev
    if (roles.includes('admin')) {
      if (resource === 'feeds' && action === 'change_position') {
        return Promise.resolve({ can: false })
      }

      if (resource === 'merchants' && action === 'dev') {
        return Promise.resolve({ can: false })
      }

      return Promise.resolve({ can: true })
    }

    // GOD mode
    if (roles.includes('developer')) {
      return Promise.resolve({ can: true })
    }

    // SP Support
    //
    if (roles.includes('support')) {
      if (resource === 'gateways' && action === 'list') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'disputes') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'webhooks') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'team_deposits') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'payment_requisites' && action === 'list') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'partners' && (action === 'list' || action === 'filter')) {
        return Promise.resolve({ can: true })
      }
      if (resource === 'merchants' && (action === 'list' || action === 'filter')) {
        return Promise.resolve({ can: true })
      }
      if (resource === 'payouts' && action === 'list') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'scoring/clients' && action === 'view_id') {
        return Promise.resolve({ can: true })
      }
      if (
        resource === 'operations' &&
        (action === 'list' ||
          action === 'create' ||
          action === 'view' ||
          action === 'update_payment_requisite' ||
          action === 'propose_amount' ||
          action === 'override_approved_amount' ||
          action === 'view_client_id')
      ) {
        return Promise.resolve({ can: true })
      }
    }

    // SP Financier
    //
    if (roles.includes('financier')) {
      if (
        resource === 'merchants' &&
        (action === 'show' || action === 'list' || action === 'filter')
      ) {
        return Promise.resolve({ can: true })
      }
      if (
        resource === 'operations' &&
        (action === 'list' ||
          action === 'view_client_id' ||
          action === 'margin' || 
          action === 'view_fee')
      ) {
        return Promise.resolve({ can: true })
      }
      if (resource === 'partners' &&
        (action === 'list' || action === 'show' || action === 'filter')) {
        return Promise.resolve({ can: true })
      }
      if (resource === 'payouts' && action === 'list') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'reports') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'exports') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'agents' && action === 'list') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'billing/agreements' &&
        (action === 'list' || action === 'create' || action === 'delete')) {
        return Promise.resolve({ can: true })
      }
      if (resource === 'teams' &&
        (action === 'list' || action === 'show' || action === 'filter')) {
        return Promise.resolve({ can: true })
      }
    }

    if (roles.includes('merchant_admin')) {
      if (
        resource === 'merchants' &&
        (action === 'show' ||
          action === 'list' ||
          action === 'edit' ||
          action === 'filter')
      ) {
        return Promise.resolve({ can: true })
      }
      if (resource === 'dev') {
        return Promise.resolve({ can: true })
      }
      if (
        resource === 'operations' &&
        (action === 'list' || action === 'scope_filter' || action === 'view_client_id')
      ) {
        return Promise.resolve({ can: true })
      }
      if (resource === 'payouts' && action === 'list') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'dealings' && action === 'create') {
        return Promise.resolve({ can: false })
      }
      if (resource === 'dealings' && action === 'delete') {
        return Promise.resolve({ can: false })
      }
      if (resource === 'reports' && action === 'list') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'exports') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'webhooks') {
        return Promise.resolve({ can: true })
      }
    }

    if (roles.includes('merchant_support')) {
      if (
        resource === 'operations' &&
        (action === 'list' || action === 'scope_filter' || action === 'view_client_id')
      ) {
        return Promise.resolve({ can: true })
      }
      if (resource === 'payouts' && action === 'list') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'merchants' && action === 'filter') {
        return Promise.resolve({ can: true })
      }
    }

    // список заказов
    // список пейаутов
    // список отчетов
    // экспорт
    // фильтр по мерчам
    if (roles.includes('merchant_accountant')) {
      if (
        resource === 'operations' &&
        (action === 'list' || action === 'scope_filter' || action === 'view_client_id')
      ) {
        return Promise.resolve({ can: true })
      }
      if (resource === 'payouts' && action === 'list') {
        return Promise.resolve({ can: true })
      }

      if (resource === 'reports' && action === 'list') {
        return Promise.resolve({ can: true })
      }

      if (resource === 'exports') {
        return Promise.resolve({ can: true })
      }

      if (resource === 'merchants' && action === 'filter') {
        return Promise.resolve({ can: true })
      }
    }

    if (roles.includes('partner_admin')) {
      if (resource === 'scoring/clients' && action === 'view_id') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'disputes') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'payment_requisites') {
        return Promise.resolve({ can: true })
      }

      if (resource === 'feeds') {
        return Promise.resolve({ can: true })
      }

      if (resource === 'feed_items') {
        return Promise.resolve({ can: true })
      }

      if (resource === 'trade_orders') {
        return Promise.resolve({ can: true })
      }

      if (resource === 'partners' && (action === 'list' || action === 'filter')) {
        return Promise.resolve({ can: true })
      }

      if (resource === 'dealings' && action === 'list') {
        return Promise.resolve({ can: true })
      }

      if (
        resource === 'operations' &&
        (action === 'list' ||
          action === 'approve' ||
          action === 'scope_filter' ||
          action === 'view')
      ) {
        return Promise.resolve({ can: true })
      }

      if (resource === 'payouts' && action === 'list') {
        return Promise.resolve({ can: true })
      }

      if (resource === 'shifts') {
        return Promise.resolve({ can: true })
      }

      if (resource === 'reports' && action === 'list') {
        return Promise.resolve({ can: true })
      }
    }

    return Promise.resolve({ can: false })
  },
}
