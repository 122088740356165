import {
  CrudFilters, getDefaultFilter,
  IResourceComponentsProps, useApiUrl,
  useCustomMutation,
  useInvalidate,
  usePermissions,
  useTranslate,
} from '@refinedev/core'
import { useMediaQuery } from 'react-responsive'
import { OrdersTable } from './OrdersTable'
import { GenericResponse, IGateway, IOrder } from '../../interfaces'
import { OperationKindFilter } from '../../components/OperationKindFilter'
import { List, useSelect, useTable } from '@refinedev/antd'
import { Form, Select } from 'antd'
import React from 'react'

import './styles.css'
import { OrdersMobileTable } from './OrdersMobileTable'

export const OrderList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const isMobile = useMediaQuery({ query: '(min-width: 1024px)' })

  const apiUrl = useApiUrl()
  const invalidate = useInvalidate()
  const { mutate } = useCustomMutation<GenericResponse>()

  const { tableProps, searchFormProps, filters } = useTable<IOrder>({
    resource: 'trade_orders',
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const {
        gateway_id,
        kind,
      } = params

      filters.push({
        field: 'gateway_id',
        operator: 'eq',
        value: gateway_id,
      })

      filters.push({
        field: 'kind',
        operator: 'eq',
        value: kind,
      })

      return filters
    },
  })

  const invalidateTradeOrders = () => {
    invalidate({
      resource: 'trade_orders',
      invalidates: ['list'],
    })
  }

  const onCancel = (id: string) => {
    mutate(
      {
        url: `${apiUrl}/trade_orders/${id}/cancel`,
        method: 'post',
        values: {},
      },
      {
        onSuccess: () => {
          invalidateTradeOrders()
        },
      },
    )
  }

  const { data } = usePermissions<string>({
    v3LegacyAuthProviderCompatible: true,
  })

  const { selectProps: gatewaysSelectProps } = useSelect<IGateway>({
    resource: 'gateways',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  return (
    // todo проверить корректность роли
    <List
      title={t(`trade_orders.titles.list`)}
      canCreate={data?.includes('partner_admin')}>
      <Form
        {...searchFormProps}
        layout="horizontal"
        size={'middle'}
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
        initialValues={{
          gateway_id: getDefaultFilter('gateway_id', filters),
          kind: getDefaultFilter('kind', filters),
        }}
      >
        <div className={'order-list-filters'}>
          <Form.Item name="gateway_id" className={'order-list-filters_gateway'}>
            <Select
              {...gatewaysSelectProps}
              allowClear
              showSearch
              placeholder={t('agreements.filter.gateway.placeholder')}
              filterOption={(input, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="kind">
            <OperationKindFilter
              operation_kinds={['payout', 'income']}
              default_operation_kind={'payout'}
            />
          </Form.Item>
        </div>
      </Form>
      {isMobile && <OrdersTable tableProps={tableProps} onCancel={onCancel} />}
      {!isMobile && <OrdersMobileTable tableProps={tableProps} onCancel={onCancel} />}
    </List>
  )
}