import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Edit, useForm, useSelect } from '@refinedev/antd'
import { IGateway, IOrder, ITeam } from '../../interfaces'
import { Form, InputNumber, Select } from 'antd'

export const OrderEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IOrder>({
    resource: 'trade_orders',
    action: 'edit',
    redirect: 'list',
  })

  const { selectProps: gatewaySelectProps } = useSelect<IGateway>({
    resource: 'gateways',
    optionLabel: 'name',
    optionValue: 'id',
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })
  const { selectProps: teamSelectProps } = useSelect<ITeam>({
    resource: 'teams',
    optionLabel: 'name',
    optionValue: 'id',
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  let kindOptions = [
    {
      label: t('trade_orders.kinds.income'),
      value: 'income',
    }, {
      label: t('trade_orders.kinds.payout'),
      value: 'payout',
    },
  ]

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="kind"
          label={t('trade_orders.fields.kind')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={kindOptions}
            // defaultValue={kindOptions[0]}
          />
        </Form.Item>
        <Form.Item
          name="team_id"
          label={t('trade_orders.fields.team')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...teamSelectProps}
                  allowClear
                  showSearch />
        </Form.Item>
        <Form.Item
          name="amount"
          label={t('trade_orders.fields.amount')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            type="number"
            decimalSeparator="."
            precision={2}
            min={0}
          />
        </Form.Item>
        <Form.Item
          name="percent"
          label={t('trade_orders.fields.percent')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            type="number"
            decimalSeparator="."
            precision={2}
            min={0}
            max={100}
          />
        </Form.Item>
        <Form.Item
          name="gateway_id"
          label={t('trade_orders.fields.gateway')}
        >
          <Select {...gatewaySelectProps}
                  allowClear
                  showSearch />
        </Form.Item>
      </Form>
    </Edit>
  )
}