import { IOrder } from '../../interfaces'
import { Space, Button, TableProps, Table, Popconfirm } from 'antd'

import './mobile-table-styles.css'
import { EditButton } from '@refinedev/antd'
import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { CloseCircleOutlined } from '@ant-design/icons'

export const OrdersMobileTable: React.FC<
  IResourceComponentsProps & {
  tableProps: TableProps<IOrder>
  onCancel: (id: string) => void
}
> = ({ tableProps, onCancel }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        pageSize: 10,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column<IOrder>
        dataIndex={'percent'}
        title={t('trade_orders.trade_orders')}
        render={(_, order) => (
          <div className={'order-item'}>
            <div>
              <div>{order.gateway?.name ?? 'All'} - {order.currency}</div>
              <div>{order.percent} %</div>
            </div>
            <div>
              {
                // order.is_owner &&
                <Space size="middle">
                  <EditButton recordItemId={order.id} size={'small'} />
                  <Popconfirm
                    title={t('trade_orders.confirm.cancel_trade_order.title')}
                    description={t('trade_orders.confirm.cancel_trade_order.description')}
                    onConfirm={() => {
                      onCancel(order.id)
                    }}
                    okText={t('trade_orders.confirm.cancel_trade_order.ok')}
                    cancelText={t('trade_orders.confirm.cancel_trade_order.cancel')}
                  >
                    <Button icon={<CloseCircleOutlined />}
                            size={'small'}>
                      {t('trade_orders.actions.cancel')}
                    </Button>
                  </Popconfirm>
                </Space>
              }
            </div>
          </div>
        )}
      />
    </Table>
  )
}
