import {
  IResourceComponentsProps,
  useTranslate,
  useMany,
  GetManyResponse,
  useCan,
  CrudFilters,
  getDefaultFilter,
} from '@refinedev/core'

import { EditButton, List, useTable } from '@refinedev/antd'

import { Table, Space, Typography, Skeleton, Form, Input } from 'antd'
import { IPartner, ITeam } from 'interfaces'

import AddTeamPartnerButton from 'components/AddTeamPartnerButton'
import { ShortId } from 'components/ShortId'
import BooleanTag from 'components/BooleanTag'
import {
  formattedAmount,
} from 'utils'
import { SearchOutlined } from '@ant-design/icons'

const { Text } = Typography

const Partner: React.FC<{
  id: string
  partnersData: GetManyResponse<IPartner> | undefined
}> = ({ id, partnersData }) => {
  const partner = partnersData && partnersData.data.find((item) => id === item.id)

  return (
    <Text code ellipsis style={{ display: 'flex', alignItems: 'center' }}>
      {partner?.name}
    </Text>
  )
}

export const TeamsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, searchFormProps, filters } = useTable<ITeam>({
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const { search } = params

      filters.push({
        field: 'search',
        operator: 'eq',
        value: search,
      })

      return filters
    },
    pagination: {
      pageSize: 20,
    },
  })

  const { data: canCreatePartner } = useCan({
    resource: 'partners',
    action: 'create',
  })

  const { data: canAddPartner } = useCan({
    resource: 'partners',
    action: 'add_partner',
  })

  const partnersIds =
    tableProps?.dataSource?.flatMap((item: ITeam) =>
      item.partner_ids?.concat(item.partner_ids)
    ) ?? []

  const { data: partnersData, isLoading: partnersLoading } = useMany<IPartner>({
    resource: 'partners',
    ids: partnersIds,
    meta: {
      filters: [
        {
          field: 'compact',
          operator: 'eq',
          value: true,
        },
      ],
    },
    queryOptions: {
      enabled: !!(partnersIds.length > 0),
    },
  })

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <List canCreate={canCreatePartner?.can}>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          search: getDefaultFilter('search', filters),
        }}
      >
        <Form.Item name="search">
          <Input
            placeholder={t('teams.filter.search.placeholder')}
            prefix={<SearchOutlined />}
            allowClear
          />
        </Form.Item>
      </Form>
      <Table
        scroll={{ x: '100%' }}
        {...tableProps}
        rowKey="id"
        pagination={
          tableProps.pagination && (tableProps.pagination?.total ?? 0) > 20
            ? {
                ...tableProps.pagination,
                pageSize: 20,
                position: ['bottomLeft'],
                size: 'small',
              }
            : false
        }
      >
        <Table.Column<ITeam>
          ellipsis
          dataIndex="id"
          key="id"
          title={t('teams.fields.id')}
          render={(value) => <ShortId value={value} />}
        />
        <Table.Column<ITeam>
          dataIndex="name"
          key="name"
          title={t('teams.fields.name')}
        />
        <Table.Column<ITeam>
          dataIndex="partner_ids"
          key="partner_ids"
          title={t('teams.fields.partners')}
          render={(value: string[], record: ITeam) => {
            if (partnersIds.length && partnersLoading) {
              return <Skeleton.Input active />
            }

            return (
              <Space wrap>
                {value.map((partner_id) => (
                  <Partner id={partner_id} partnersData={partnersData} />
                ))}
                {canAddPartner?.can && (
                  <AddTeamPartnerButton id={record.id} />
                )}
              </Space>
            )
          }}
        />
        <Table.Column<ITeam>
          dataIndex="deposit_balance"
          key="deposit_balance"
          title={t('teams.fields.deposit_balance')}
          render={(value) => (value ? formattedAmount(value) : 'Нет депозита')}
        />
        <Table.Column<ITeam>
          ellipsis
          dataIndex="currency"
          key="currency"
          title={t('teams.fields.currency')}
        />
        <Table.Column<ITeam>
          dataIndex="telegram_chat_id"
          key="telegram_chat_id"
          title={t('teams.fields.telegram_chat_id')}
        />
        <Table.Column<ITeam>
          dataIndex="trade_orders_enabled"
          key="trade_orders_enabled"
          title={t('teams.fields.trade_orders_enabled')}
          render={(value) => <BooleanTag value={value} />}
        />
        <Table.Column<ITeam>
          dataIndex="edit"
          render={(_, record) => <EditButton recordItemId={record.id} />}
        />
      </Table>
    </List>
  )
}
