import { Button, Popconfirm, Space, Table, TableProps } from 'antd'
import { IOrder } from '../../interfaces'
import { EditButton, TextField } from '@refinedev/antd'
import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { CloseCircleFilled, CloseCircleOutlined } from '@ant-design/icons'


export const OrdersTable: React.FC<
  IResourceComponentsProps & {
  tableProps: TableProps<IOrder>
  onCancel: (id: string) => void
}
> = ({ tableProps, onCancel }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        pageSize: 10,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column<IOrder>
        dataIndex={'percent'}
        ellipsis
        key="percent"
        title={t('trade_orders.fields.percent')}
        width={1}
      />
      <Table.Column<IOrder>
        dataIndex={'currency'}
        ellipsis
        key="currency"
        title={t('trade_orders.fields.currency')}
        width={1}
      />
      <Table.Column<IOrder>
        dataIndex={['gateway', 'name']}
        ellipsis
        key="gateway"
        title={t('trade_orders.fields.gateway')}
        render={(value) => (
          <TextField
            style={{ color: value ? '' : 'grey' }}
            value={value ? value : 'Не указан'}
          />
        )}
      />
      <Table.Column<IOrder>
        dataIndex="actions"
        ellipsis
        key="actions"
        width={'1rem'}
        render={(_, order) => (
          //record.is_owner &&
          <Space size="middle">
            <EditButton recordItemId={order.id} />
            <Popconfirm
              title={t('trade_orders.confirm.cancel_trade_order.title')}
              description={t('trade_orders.confirm.cancel_trade_order.description')}
              onConfirm={() => {
                onCancel(order.id)
              }}
              okText={t('trade_orders.confirm.cancel_trade_order.ok')}
              cancelText={t('trade_orders.confirm.cancel_trade_order.cancel')}
            >
              <Button icon={<CloseCircleOutlined />}>{t('trade_orders.actions.cancel')}</Button>
            </Popconfirm>
          </Space>
        )}
      />
    </Table>
  )
}